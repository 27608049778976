<template>
    <b-modal ref="modal" id="fraudWarning" size="lg" title="Overview and Fraud Warning">
        <div class="warning-wrapper">
            <div class="w-100 d-flex justify-content-center mb-4 mt-2">
                <img
                    alt="logo"
                    class="d-none d-md-block d-sm-none"
                    :src="require('../../../public/img/logo-big.png')"
                />
            </div>

            <h1>Overview and Fraud Warning</h1>
            <div id="legalNotice">
                <div>
                    <span style="font-weight: bold; font-size: 1em;">
                        1. OVERVIEW AND FRAUD WARNING
                    </span>
                    <p>
                        <!--Introduction-->
                        This application is for an insurance policy to repair my jewelry or replace it with like kind
                        and quality. I understand that as part of the underwriting review process, Jewelers Mutual Group
                        may request credit reference checks and/or loss experience reports from appropriate sources. I
                        understand that a fraudulent act, which is a crime, is committed when a person knowingly and
                        with intent to defraud or mislead: (1) files an insurance application containing false
                        information, or (2) conceals information concerning any material fact.
                    </p>
                </div>

                <div>
                    <span style="font-weight: bold;">Fraud Warning:&nbsp;&nbsp;</span>
                    <p>
                        {{ stateFraudWarning }}
                    </p>
                </div>

                <!--E-Signature Agreement-->
                <div>
                    <span style="font-weight: bold; font-size: 1em;">
                        2. E-SUBMISSION OF APPLICATION; E-SIGNATURE AGREEMENT
                    </span>
                    <p>
                        <span style="font-weight: bold;">IMPORTANT:</span>
                        &nbsp;You are submitting an application for insurance with Jewelers Mutual Insurance Company, SI
                        (“Jewelers Mutual,” “we,” or “us”). In order for you to do so, we describe below an overview of
                        the insurance as well as certain legal rights and responsibilities relating to the coverage, the
                        application, and your submission of the application.
                        <span style="font-style: italic;">
                            Please read everything in this page carefully and contact us if you have any questions.
                        </span>
                    </p>
                </div>

                <div>
                    <span style="font-weight: bold">
                        Overview of Rates, Rules and Premiums for Coverage
                    </span>
                    <br />
                    <br />
                    1. Yearly Premium Rates
                    <br />
                    <br />
                    The total yearly premium is based on the retail value for each item of jewelry insured. The rate is
                    based on the residence location of the person possessing the jewelry.
                    <br />
                    <br />
                    2. Inspections
                    <br />
                    <br />
                    We recommend periodic inspections of your insured jewelry. An inspection could uncover necessary
                    repairs, helps you maintain adequate insurance coverage, and is a great opportunity to have your
                    jewelry professionally cleaned.
                    <br />
                    <br />
                    3. Eligibility
                    <br />
                    <br />
                    <ol style="list-style-type: upper-alpha">
                        <li>
                            Only people living in the United States or Canada (excluding Quebec) are eligible for our
                            personal jewelry insurance.
                        </li>
                        <li>
                            Insurance is not available for loose stones not being set, damaged jewelry and watches, and
                            non-jewelry items.
                        </li>
                    </ol>
                    4. Coverage
                    <br />
                    <br />
                    Please read your policy carefully when you receive it. If you are dissatisfied with the coverage,
                    please return the policy to us within 30 days, with a written cancellation request signed by all
                    named insureds, and your premium will be refunded.
                    <br />
                    <br />
                    <span style="font-style: italic">For California residents</span>
                    , your return premium will be calculated on a pro rata basis.
                    <br />
                    <br />
                    5. Questions and Comments
                    <br />
                    <br />
                    Your jeweler is not an agent of Jewelers Mutual Insurance Company, SI and does not sell or recommend
                    insurance. Please direct all questions and comments about insurance to Jewelers Mutual Group's
                    Personal Insurance Department.
                    <br />
                    <br />
                    <span style="font-weight: bold">Consent to electronic (“e”) submission:</span>
                    <br />
                    <br />
                    <ul>
                        <li>
                            If you do not consent to e-submission, you may not submit your application using an
                            e-signature. Contact Jewelers Mutual Group toll-free at 888-346-9464 to request a paper copy
                            of any of these materials.
                        </li>
                        <li>
                            The application process does not involve any fee charged by us to use our website, but you
                            of course may incur charges from your Internet service provider (ISP). Also, your ISP may
                            experience system failures, and hyperlinks to documents may not function properly.
                        </li>
                        <li>
                            You may withdraw your consent to completing or submitting this insurance application online
                            by closing the active browser window or tab.
                        </li>
                    </ul>
                    <span style="font-style: italic">Duration of e-consent:</span>
                    Your consent and e-signature agreement applies only to your application for insurance.
                    <br />
                    <br />
                    <span style="font-weight: bold">Electronic (“e”) signature overview</span>
                    <br />
                    <br />
                    To apply for insurance with Jewelers Mutual Group using an e-signature, you must consent by clicking
                    the “Submit Application” button. By clicking this button, you acknowledge reading and understanding
                    the following “E-SIGNATURE AGREEMENT” terms and conditions. If you do not click “Submit
                    Application,” you cannot apply for insurance online, though you may request from us paper copies of
                    all documents and apply by fax or mail.
                    <br />
                    <br />
                </div>

                <div>
                    <span style="font-weight: bold">E-SIGNATURE AGREEMENT</span>
                    <br />
                    <br />
                    I understand that clicking the “Submit Application” button means the following:
                    <br />
                    <br />
                    <ol style="list-style-type: decimal">
                        <li>
                            I have read, and I understand, the “Overview of Rates, Rules and Premiums for Coverage”
                            presented above in this page.
                        </li>
                        <li>
                            I have full authority and legal capacity, whether individually or as a representative of a
                            legal entity (like a trust, for example), both to apply for insurance with Jewelers Mutual
                            Group and to consent (and I hereby consent) to any necessary background or credit check or
                            investigation related to the application for insurance.
                        </li>
                        <li>
                            I wish and intend to complete and submit to Jewelers Mutual Group an application for
                            insurance with Jewelers Mutual Group.
                        </li>
                        <li>
                            Through the “Submit Application” button, I am providing my e-signature, which is the legal
                            equivalent of submitting a document signed by hand.
                        </li>
                        <li>
                            I understand that my submission of the insurance application or payment of initial premium
                            does NOT guarantee that Jewelers Mutual Group will issue a policy to me, and I may be
                            contacted for further information before any decision is made on my application.
                        </li>
                        <li>
                            I may call 888-346-9464 to obtain a paper copy of my completed application, at no charge.
                        </li>
                        <li>
                            I UNDERSTAND THE IMPORTANCE OF TRUE AND ACCURATE INFORMATION AND HAVE READ, AND I
                            UNDERSTAND, THE
                            <span style="text-decoration: underline;">
                                OVERVIEW &amp; FRAUD WARNING
                            </span>
                            MESSAGE INCLUDED IN THIS PAGE.
                        </li>
                    </ol>
                </div>

                <!--Message for Canadians-->
            </div>
        </div>

        <template #modal-footer="{ ok }">
            <div class="footer-buttons">
                <Button type="submit" text="ok" @clickEvent="ok()" />
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import fraudWarnings from '@/constants/fraudWarnings.json';
import Button from '@/components/FormGroups/Button';

export default {
    name: 'FraudWarningModal',
    components: { Button },
    computed: {
        ...mapGetters(['applicantAndWearers']),
        country() {
            return _get(this.applicantAndWearers, 'applicant.country', '');
        },
        state() {
            return _get(this.applicantAndWearers, 'applicant.state', '');
        },
        stateFraudWarning() {
            if (fraudWarnings[this.country]) {
                return fraudWarnings[this.country][this.state] || fraudWarnings[this.country].default;
            } else {
                return fraudWarnings.US.default;
            }
        }
    },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
</style>
