<template>
    <b-icon class="b-i no-select pointer" icon="pencil-fill" />
</template>

<script>
export default {
    name: 'EditIcon'
};
</script>

<style lang="scss" scoped>
.b-i {
    vertical-align: middle;
    color: $white;
    background-color: $blue-icon;
    border-radius: 50%;
    padding: 5px;
}
</style>
