<template>
    <div>
        <Title heading="Review Your Application" />
        <div v-if="loadingQuote" class="loading-container">
            <div class="loading-image-container">
                <div>
                    <loading-spinner />
                </div>
            </div>
            <div class="loading-message">
                Updating your quote
            </div>
        </div>
        <b-form @submit.prevent="submit" v-else-if="hasRate">
            <div v-if="submitError" class="submit-policy-error">
                We are unable to submit your request at this time. Please try again later.
            </div>
            <div v-if="creatingAccount" class="loading-container">
                <div class="loading-image-container">
                    <loading-spinner />
                </div>
            </div>
            <Panel v-if="applicant">
                <b-row no-gutters>
                    <b-col class="font-weight-bold">Applicant:</b-col>
                    <b-col class="d-flex flex-column">
                        <div class="font-weight-bold text-capitalize">
                            {{ applicant.firstName }}&nbsp;{{ applicant.lastName }}
                        </div>
                        <div>{{ applicant.address }}</div>
                        <div>{{ applicant.city }},&nbsp;{{ applicant.state }}&nbsp;{{ applicant.zipCode }}</div>
                    </b-col>
                    <b-col class="d-flex flex-column justify-content-end">
                        <div />
                        <div>{{ applicant.phoneNumber }}</div>
                        <div>{{ applicant.emailAddress }}</div>
                    </b-col>
                </b-row>
            </Panel>
            <div class="my-4" />
            <Panel>
                <b-row class="row-header" no-gutters>
                    <b-col md="2">Item</b-col>
                    <b-col md="3">Jewelry Wearer</b-col>
                    <b-col md="3">Jewelry Details</b-col>
                    <b-col md="2">Yearly Premium</b-col>
                    <b-col md="2" />
                </b-row>
                <b-row v-for="(jewelry, i) in getJewelries" :key="i" class="table-div" no-gutters>
                    <b-col cols="12">
                        <b-row no-gutters>
                            <b-col class="table-left" cols="9" lg="2">
                                <div class="font-weight-bold visible-sm">
                                    <div class="blue-dot" />
                                    Item
                                </div>
                                <div class="font-weight-bold text-with-bullet sm-ml">
                                    <div class="blue-dot hide-sm" />
                                    {{ jewelry.name }}
                                </div>
                                <div class="sm-ml">
                                    <DecimalPrice :price="jewelry.price" />
                                    value
                                </div>
                                <div class="sm-ml">
                                    <DecimalPrice :price="jewelry.quote.Deductible" />
                                    deductible
                                </div>
                                <EditIcon class="h4 visible-sm mt-2 mb-4 sm-ml" @click="$router.push('/')" />
                            </b-col>
                            <b-col class="sm-ml" cols="8" lg="3">
                                <div class="font-weight-bold text-capitalize">
                                    <div>
                                        {{ jewelry.wearer.firstName || '' }}&nbsp;{{ jewelry.wearer.lastName || '' }}
                                    </div>
                                </div>
                                <EditIcon class="h4 visible-sm mt-2 mb-4" @click="$router.push('/applicant')" />
                            </b-col>
                            <b-col class="sm-ml" cols="8" lg="3">
                                <div>Appraisal Uploaded? {{ jewelry.appraisalUploaded }}</div>
                                <div>Alarm System? {{ jewelry.alarmSystem }}</div>
                                <EditIcon class="visible-sm mt-2 mb-4 h4" @click="back" />
                            </b-col>
                            <b-col class="space-between-sm" cols="12" lg="2">
                                <span class="visible-sm font-weight-bold">Item Yearly Premium</span>
                                <DecimalPrice :price="jewelry.premium" class="font-weight-bold" />
                            </b-col>
                            <b-col class="close-sm" cols="2">
                                <b-icon class="h4 pointer" icon="x-circle" @click="remove(i)" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="mt-2 table-left hide-sm" cols="12">
                        <b-row no-gutters>
                            <b-col md="2">
                                <span @click="$router.push('/')">
                                    <EditIcon class="h4" />
                                </span>
                            </b-col>
                            <b-col md="3">
                                <span @click="$router.push('/applicant')">
                                    <EditIcon class="h4" />
                                </span>
                            </b-col>
                            <b-col md="3">
                                <span @click="back">
                                    <EditIcon class="h4" />
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </Panel>
            <b-row class="justify-content-between" no-gutters>
                <b-col class="add-item center-mobile" cols="12" md="6">
                    <span class="pointer" @click="addItem">
                        <b-icon class="h5" icon="plus-circle" />
                        Add another item
                    </span>
                </b-col>
                <b-col class="center-mobile" cols="12" md="6">
                    <b-row no-gutters class="summary-line">
                        <b-col cols="6">
                            Discounts:
                        </b-col>
                        <b-col class="d-flex justify-content-end discount" cols="6" md="4">
                            <DecimalPrice :price="rateTotals.discount" />
                        </b-col>
                    </b-row>
                    <b-row no-gutters class="summary-line">
                        <b-col cols="6">
                            Taxes & Surcharges:
                        </b-col>
                        <b-col class="d-flex justify-content-end total-taxes" cols="6" md="4">
                            <DecimalPrice :price="rateTotals.taxesAndFees" />
                        </b-col>
                    </b-row>
                    <b-row class="font-weight-bold summary-line" no-gutters>
                        <b-col cols="6">
                            Total Yearly Premium:
                        </b-col>
                        <b-col class="d-flex justify-content-end total-price" cols="6" md="4">
                            <DecimalPrice :price="rateTotals.total + rateTotals.taxesAndFees" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row align-h="end" class="mt-3">
                <b-col cols="12" md="9">
                    <b-form-checkbox
                        :model="form.fraud"
                        :state="showErrors && !form.fraud ? false : null"
                        name="fraud-overview"
                        @change="v => (form.fraud = v)"
                    >
                        I have read and understand the contents of the
                        <b-link @click.stop="showFraudWarning">Overview and Fraud Warning</b-link>
                    </b-form-checkbox>

                    <FraudWarningModal ref="fraudWarning" />
                </b-col>
            </b-row>
            <b-row align-h="end" class="mt-3" v-if="applicant.country === 'XXXX'">
                <b-col cols="12" md="9">
                    <b-form-checkbox
                        :model="form.creditConsent"
                        name="credit-consent"
                        @change="v => (form.creditConsent = v)"
                    >
                        I acknowledge that I have read the
                        <b-link @click.stop="showCreditConsent">Credit Consent Form</b-link>
                        and authorize Jewelers Mutual Insurance Company, SI to access my credit score (Optional)
                    </b-form-checkbox>

                    <CreditConsentModal ref="creditConsent" />
                </b-col>
            </b-row>
            <b-row align-h="end" class="mt-3" v-if="applicant.country === 'US' && applicant.state === 'AL'">
                <b-col cols="12" md="9">
                    <b-form-checkbox
                        :model="form.stock"
                        :state="showErrors && !form.stock ? false : null"
                        name="stock-disclaimer"
                        @change="v => (form.stock = v)"
                    >
                        I acknowledge that Jewelers Mutual Insurance Company, SI (JMIC) is a stock insurer, and I will
                        have membership rights in Jewelers Mutual Holding Company, JMIC's parent company, if I become a
                        policyholder.
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row align-h="end" class="mt-3">
                <b-col cols="12" md="9">
                    <b-form-checkbox :model="form.paperless" name="accept-paperless" @change="setPaperless">
                        Sign me up for paperless delivery. I authorize Jewelers Mutual Group to send billing and policy
                        documents to me electronically. I understand that I can cancel at any time. I have read and
                        consent to the
                        <b-link @click.stop="showDeliveryDisclosure">Paperless Delivery Disclosure</b-link>
                        .
                    </b-form-checkbox>

                    <PaperlessDeliveryModal ref="paperlessDeliveryDisclosure" />
                </b-col>
            </b-row>
            <b-row class="mt-5" no-gutters>
                <b-col class="mobile-reverse" cols="12">
                    <div>
                        <span class="save" @click="saveAndFinishLater">Save & Finish Later</span>
                    </div>
                    <div>
                        <Button text="Next" type="submit" :disabled="creatingAccount || submitDisabled" />
                    </div>
                </b-col>
                <b-col cols="12">
                    <div><span class="back" @click="back">&lt;&nbsp;Back</span></div>
                </b-col>
            </b-row>
        </b-form>

        <div v-else-if="!!invalidItems.length || allItemsInvalid">
            <b-row v-if="allItemsInvalid">
                Sorry, the items may not be added at this time. To continue, please call
                <b-link href="tel:888-346-9464">888-346-9464</b-link>
                .
            </b-row>
            <div v-else>
                <b-row v-for="(item, i) in invalidItems" :key="i">
                    <b-col>
                        Sorry, the recently added item with a value of
                        <DecimalPrice :price="item.value" />
                        may not be added at this time.
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        Please go back and apply without {{ invalidItems.length > 1 ? 'these items' : 'this item' }} or
                        call
                        <b-link href="tel:888-346-9464">888-346-9464</b-link>
                        .
                    </b-col>
                </b-row>
            </div>
            <b-row class="mt-5">
                <b-col cols="12">
                    <div><span class="back" @click="back">&lt;&nbsp;Back</span></div>
                </b-col>
            </b-row>
        </div>

        <div v-else>
            Sorry, we are unable to update your quote at this time.
            <span v-if="!!trackingId">
                <b-link @click="reloadRateQuote">Try again</b-link>
                or check back later.
            </span>
            <span v-else>Please check back later.</span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SECURITY_OPTIONS } from '@/constants/jewelryItems';
import jewelryValueRules from '@/utils/jewelry-value-rules';
import PaperlessDeliveryModal from '@/components/Review/PaperlessDeliveryModal';
import FraudWarningModal from '@/components/Review/FraudWarningModal';
import CreditConsentModal from '@/components/Review/CreditConsentModal';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import Title from '@/components/Common/Title';
import Panel from '@/components/Common/Panel';
import DecimalPrice from '@/components/Common/DecimalPrice';
import Button from '@/components/FormGroups/Button';
import EditIcon from '@/components/Common/EditIcon';
import axios from 'axios';

export default {
    name: 'Review',
    components: {
        PaperlessDeliveryModal,
        FraudWarningModal,
        CreditConsentModal,
        EditIcon,
        DecimalPrice,
        Panel,
        Title,
        Button,
        LoadingSpinner
    },
    data: () => ({
        loadingQuote: false,
        loadError: false,
        form: {
            fraud: false,
            paperless: false,
            creditConsent: false,
            stock: false
        },
        showErrors: false,
        creatingAccount: false,
        submitError: false,
        invalidItems: [],
        allItemsInvalid: false
    }),
    mounted() {
        window.scrollTo(0, 0);
        if (
            !this.jewelryItems ||
            !this.jewelryItems.length ||
            !this.jewelryItems[0].quotes ||
            !this.jewelryItems[0].quotes.length ||
            !this.applicant ||
            !this.applicant.gender
        ) {
            this.$router.replace('/results');
            return;
        }
        this.form.paperless = this.applicant.isPaperlessEnrolled;

        const underwritingRulesValid = jewelryValueRules(this.jewelryItems, this.applicant.country);
        this.invalidItems = underwritingRulesValid.invalidItems;
        this.allItemsInvalid = underwritingRulesValid.allItemsInvalid;

        if (this.invalidItems.length || this.allItemsInvalid) {
            if (this.rateQuote) {
                this.clearRateQuote();
            }
        } else {
            this.loadRateQuoteWithSpinner();
        }
    },
    computed: {
        ...mapGetters(['rateQuote', 'jewelryItems', 'rateTotals', 'applicantAndWearers']),
        applicant() {
            return this.applicantAndWearers.applicant;
        },
        wearers() {
            return this.applicantAndWearers;
        },
        hasRate() {
            return !!(this.rateQuote && this.rateQuote.ResponseItems && this.rateQuote.ResponseItems.length);
        },
        trackingId() {
            if (this.rateQuote && this.rateQuote.trackingId) {
                return this.rateQuote.trackingId;
            }
            return null;
        },
        submitDisabled() {
            return !!this.invalidItems.length || this.allItemsInvalid;
        },
        getJewelries() {
            return this.jewelryItems.map((jewelry, i) => {
                const result = {
                    name: jewelry.name,
                    price: jewelry.value,
                    quote: {},
                    wearer: this.getWearerFromList(this.wearers.wearerJewelryItems[i]),
                    appraisalUploaded: 'No',
                    alarmSystem:
                        this.applicant.security === SECURITY_OPTIONS[0].value ? SECURITY_OPTIONS[0].text : 'Yes',
                    premium: 0,
                    discount: 0,
                    fullPremium: 0
                };

                if (this.rateQuote) {
                    const itemQuote = this.rateQuote.ResponseItems[i];
                    result.quote = itemQuote;
                    result.premium = itemQuote.AnnualPremium;
                    result.discount = -1 * (itemQuote.TotalRiskModifierAdjustment || 0);
                    result.fullPremium = result.premium + result.discount;
                }

                return result;
            });
        }
    },
    methods: {
        ...mapActions([
            'loadRateQuote',
            'loadRateQuoteById',
            'clearRateQuote',
            'createAccountInGuidewire',
            'addJewelry',
            'removeJewelry',
            'setApplicantAndWearers'
        ]),
        showDeliveryDisclosure() {
            this.$refs.paperlessDeliveryDisclosure.show();
        },
        showFraudWarning() {
            this.$refs.fraudWarning.show();
        },
        showCreditConsent() {
            this.$refs.creditConsent.show();
        },
        setPaperless(v) {
            this.form.paperless = v;
            const applicantAndWearers = this.applicantAndWearers;
            applicantAndWearers.applicant.isPaperlessEnrolled = v;
            this.setApplicantAndWearers(applicantAndWearers);
        },
        areDisclaimersValid() {
            if (!this.form.fraud) {
                return false;
            }
            if (this.applicant.country === 'US' && this.applicant.state === 'AL' && !this.form.stock) {
                return false;
            }
            return true;
        },
        submit() {
            if (this.invalidItems.length || this.allItemsInvalid) {
                // NOTE, there is no valid user flow where this UW check should be thrown.
                // It was added to the system as a just-in-case before sending the user through to the payment screens.
                this.invalidItems.map(async item => {
                    const payload = {
                        mode: 'uw_limit_met',
                        eventCategory: 'Underwriting Rules',
                        eventAction: 'Item value limit hit',
                        eventLabel: 'Review Page',
                        eventValue: item.value,
                        eventJewelryType: item.name
                    };

                    await axios.post(`/api/session/segment`, payload);
                });
                return;
            }
            if (!this.areDisclaimersValid()) {
                this.showErrors = true;
                return;
            }
            this.showErrors = false;
            this.creatingAccount = true;

            const guidewireAccountResponse = this.createAccountInGuidewire()
                .catch(() => {
                    this.submitError = true;
                })
                .then(() => {
                    this.creatingAccount = false;
                });
            guidewireAccountResponse;
        },
        remove(jewelryIndex) {
            this.removeJewelry(jewelryIndex);
            if (this.jewelryItems.length) {
                this.loadRateQuoteWithSpinner();
            }
        },
        back() {
            if (!this.creatingAccount) {
                this.$router.push('/jewelry-details');
            }
        },
        saveAndFinishLater() {
            if (!this.creatingAccount) {
                this.$eventBus.$emit('save');
            }
        },
        addItem() {
            this.addJewelry();
            this.$eventBus.$emit('go-initial-step');
        },
        getWearerFromList({ wearerValue }) {
            if (wearerValue === 'primary') {
                return this.wearers.applicant;
            } else {
                const w = this.wearers.wearerJewelryItems.find(
                    ({ wearer }) => wearer && wearer.value === wearerValue && wearer.firstName
                );

                return w ? w.wearer : this.wearers.applicant;
            }
        },
        loadRateQuoteWithSpinner() {
            this.loadingQuote = true;
            this.loadRateQuote()
                .catch(() => {
                    this.loadError = true;
                })
                .then(() => {
                    this.loadingQuote = false;
                });
        },
        reloadRateQuote() {
            this.loadingQuote = true;
            this.loadRateQuoteById(this.trackingId)
                .catch(() => {
                    this.loadError = true;
                })
                .then(() => {
                    this.loadingQuote = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
}

.loading-image-container {
    position: relative;
    min-height: 26px;
}

.submit-policy-error {
    color: red;
    padding-top: 0.3em;
    padding-bottom: 1em;
}

.row-header {
    &:first-child {
        padding-left: 8px;
    }

    background-color: $dim-gray;
    color: $white;
    padding-top: 3px;
    padding-bottom: 3px;

    @media (max-width: 991px) {
        display: none;
    }
}

.close-sm {
    position: absolute;
    right: 0;
    text-align: end;
}

.table-div {
    position: relative;
    font-size: 12px;
    padding: 15px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
}

.table-left {
    @media (min-width: 991px) {
        padding-left: 8px;
    }
}

.add-item {
    color: $blue;
    font-weight: 700;
    margin-top: $margin-under-element;
    user-select: none;

    svg {
        vertical-align: top;
    }
}

.save {
    display: inline-block;
    color: $blue;
    font-weight: 700;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
}

.column-sm {
    @media (max-width: 991px) {
        flex-direction: column;
    }
}

.visible-sm {
    @media (min-width: 991px) {
        display: none;
    }
}

.sm-ml {
    @media (max-width: 991px) {
        margin-left: 8px;
    }
}

.hide-sm {
    @media (max-width: 991px) {
        display: none !important;
    }
}

.back {
    color: $blue;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}

.mobile-reverse {
    display: flex;
    justify-content: space-between;

    @media (max-width: 599px) {
        flex-direction: column-reverse;
        text-align: center;

        button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.discount {
    color: $blue-discount;
}

.summary-line {
    padding-bottom: 10px;
}

.blue-dot {
    background: #0097d6;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.space-between-sm {
    display: flex;
    justify-content: space-between;
}

.error {
    color: red;
}

.warning-wrapper {
    max-height: 500px;
    overflow-y: scroll;
}
</style>
