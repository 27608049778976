<template>
    <b-modal ref="modal" id="deliveryDisclosure" size="lg" title="Credit Consent Form">
        <h2>
            Credit Consent Form
        </h2>
        <p class="lead-in">
            Many insurance companies use credit scores to help determine a customer’s property insurance premium. It is
            your choice to allow us to access your credit score.
        </p>

        <h3>
            What is a credit-score?
        </h3>
        <p class="answer">
            A credit score is a 3-digit number (ranging between 300 and 900) calculated by credit reporting agencies
            based on information contained in your credit report (record of your credit and payment history) at a
            specific point in time.
        </p>

        <h3>
            Why is Jewelers Mutual Insurance Company, SI using credit scores?
        </h3>
        <p class="answer">
            Jewelers Mutual uses your credit score together with a number of other factors to determine the appropriate
            pricing available to you. If you permit us to access your credit score it will be used to determine your
            eligibility for possible discounts and deductible options. Consent will remain valid for as along as you
            remain a customer of Jewelers Mutual, or until you withdraw your consent.
        </p>

        <h3>
            What if I do not want to give consent for Jewelers Mutual Insurance Company, SI to order my credit score?
        </h3>
        <p class="answer">
            A credit score is just one of many factors used to calculate your premium. The absence of consent will not
            affect the quality or coverage features of your product.
        </p>

        <h3>
            Will my credit rating be impacted?
        </h3>
        <p class="answer">
            Although all inquiries into your credit score are recorded on your report, a credit score inquiry for
            insurance purposes will not impact your credit rating.
        </p>

        <h3>
            Where can I get more information on credit scores?
        </h3>
        <p class="answer">
            The TransUnion website (www.transunion.ca) is a great source of information.
        </p>

        <h3>
            How do you use my personal information?
        </h3>
        <p class="answer">
            We use your information to present you with information or services that you request from us, and administer
            any policy of insurance we may issue or any other product or service we sell. We may also offer you
            information about our products and services that may suit your needs.
        </p>

        <p class="mt-4">
            For the purposes of the Insurance Companies Act (Canada), this document was issued in the course of the
            Company's insurance business in Canada.
        </p>

        <template #modal-footer="{ ok }">
            <div class="footer-buttons">
                <Button type="submit" text="ok" @clickEvent="ok()" />
            </div>
        </template>
    </b-modal>
</template>

<script>
import Button from '@/components/FormGroups/Button';

export default {
    name: 'CreditConsentModal',
    components: { Button },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
h2 {
    text-align: center;
}
.lead-in {
    font-size: 10pt;
    font-weight: bold;
}
h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0;
}
.answer {
    padding-left: 3em;
}
</style>
