<template>
    <b-modal ref="modal" id="deliveryDisclosure" size="lg" title="Paperless Delivery Disclosure">
        <div class="warning-wrapper">
            <div class="w-100 d-flex justify-content-center mb-4 mt-2">
                <img
                    alt="logo"
                    class="d-none d-md-block d-sm-none"
                    :src="require('../../../public/img/logo-big.png')"
                />
            </div>

            <div style="margin-top: 10px;">
                <h2>Updated: July 27, 2017</h2>
                <br />
                <p>
                    <span class="subheading">
                        Please read this Paperless Delivery Disclosure (“Disclosure”) thoroughly as it contains
                        important information about your legal rights.
                    </span>
                    <br />
                    The words “I,” “you,” and “your” mean the policyholder(s) who desires to transact business
                    electronically. The words “we,” “us,” and “our” mean Jewelers Mutual Insurance Company, SI and its
                    affiliates or subsidiaries (“JM”). JM reserves the right to update this Disclosure from time to
                    time, and the latest version is always the version that governs the terms of your paperless
                    delivery.
                </p>
                <br />
                <p>
                    To conduct business with JM electronically, you must read and consent to the following terms and
                    conditions. By updating your “Delivery Preference” (see item 2 below) to “Online Only” and clicking
                    “Submit,” you are agreeing to the terms and conditions set forth in this Disclosure and confirming
                    that you have the hardware and software capability (see item 4 below) to receive documents
                    electronically. By agreeing to this Disclosure, you are acknowledging that you have the authority to
                    receive Documents electronically on behalf of all insureds under your policy. Further, you
                    understand and agree that JM may provide to you in electronic format only all policy‐related
                    documents, including those related to billing or claims matters, notices, endorsements, changes to
                    your policy(ies) and any other information relating to your insurance policy(ies) (collectively,
                    “Documents”) that would otherwise be mailed to you.
                </p>
                <br />
                <p>
                    <span class="subheading">1. Method for Paperless Delivery of Documents</span>
                    <br />
                    All Documents will be provided by your accessing a JM website that we will designate in an email
                    notice that we send to you at the time any Document is available. To access a Document, you will
                    need the username and password that you created to access the website. If required by applicable
                    law, we may still send you a Document through the mail.
                </p>
                <br />
                <p>
                    You will have the ability to print or save a copy of any Document through the “eDocuments” section
                    of your online JM account. You may also request that we send you a paper copy of any Document, free
                    of charge, by contacting us at:
                </p>
                <br />
                <div style="margin-left: 10px;">
                    Jewelers Mutual Insurance Group
                    <br />
                    P.O. Box 3601
                    <br />
                    Neenah, WI 54957‐3601
                    <br />
                    Toll Free Phone: 888-346-9464
                    <br />
                    Email Us:
                    <a class="jm-NavyBlue" href="mailto:Personaljewelry@jminsure.com">
                        Personaljewelry@jminsure.com
                    </a>
                </div>
                <br />
                <p>
                    <span class="subheading">
                        2. Delivery Preferences—Selecting Paperless Delivery
                    </span>
                    <br />
                    You can update your “Delivery Preference” by logging into your online JM account and selecting
                    “Account Settings.” You will have the option to enroll in paperless delivery by selecting the
                    checkbox and clicking “Submit.” This means we will begin delivering your Documents to you
                    electronically. If you do not select the checkbox, you will continue to receive your Documents via
                    US Mail. You may also contact us by calling 888-346-9464 or emailing us at
                    <a class="jm-NavyBlue" href="mailto:Personaljewelry@jminsure.com">
                        Personaljewelry@jminsure.com
                    </a>
                    .
                </p>
                <br />
                <p>
                    <span class="subheading">
                        3. Update Contact Information
                        <br />
                        It is your responsibility both to provide us with a complete email address, contact, and other
                        information related to this Disclosure and your policy and to maintain and update promptly any
                        changes in this information.
                    </span>
                    You can update your contact information by logging into your online account.
                </p>
                <br />
                <p>
                    <span class="subheading">4. Hardware and Software Requirements</span>
                    <br />
                    To access, view, and retain Documents, you must:
                </p>
                <br />
                <p></p>
                <ul>
                    <li>Have a device that connects to the Internet</li>
                    <li>Have access to an email account</li>
                    <li>Have access to an internet browser</li>
                </ul>
                <p></p>
                <p>
                    All Documents within our website are provided in PDF format. Adobe Reader 9.0 or later versions is
                    required. A free copy of Adobe Reader may be obtained from the Adobe website at:
                    <a class="jm-NavyBlue" target="_blank" href="http://www.adobe.com">
                        www.adobe.com
                    </a>
                    .
                </p>
                <br />
                <p>
                    <span class="subheading">5. Withdraw Your Consent to Paperless Delivery</span>
                    <br />
                    You may withdraw your consent to paperless delivery by logging into your online account and
                    selecting “Account Settings.” Deselect the paperless delivery checkbox and click “Submit” to update
                    your preference. This means we will stop delivering your Documents to you electronically and send
                    them to you via US Mail. You may also contact us by calling 888-346-9464 or emailing us at
                    <a class="jm-NavyBlue" href="mailto:Personaljewelry@jminsure.com">
                        Personaljewelry@jminsure.com
                    </a>
                    .
                </p>
                <br />
                <p>
                    <span class="subheading">6. Confirmation of Paperless Delivery</span>
                    <br />
                    A confirmation message will appear immediately upon your consent to transact business electronically
                    with us. If you have any questions about Paperless Delivery, you should contact us by calling
                    888-346-9464 or emailing us at
                    <a class="jm-NavyBlue" href="mailto:Personaljewelry@jminsure.com">
                        Personaljewelry@jminsure.com
                    </a>
                    .
                </p>
                <br />
                <p>
                    <span class="subheading">7. Retain a Copy for Your Records</span>
                    <br />
                    We recommend that you retain a copy of this Disclosure, along with our Privacy Policy and Terms of
                    Use for your records and that you periodically check for updates to any of these documents.
                </p>
                <br />
            </div>
        </div>

        <template #modal-footer="{ ok }">
            <div class="footer-buttons">
                <Button type="submit" text="ok" @clickEvent="ok()" />
            </div>
        </template>
    </b-modal>
</template>

<script>
import Button from '@/components/FormGroups/Button';

export default {
    name: 'PaperlessDeliveryModal',
    components: { Button },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
</style>
